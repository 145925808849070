import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C3915F" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/Yohachi-Sushi-169349519752621">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sun Sushi Facebook"
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/sun-sushi-vancouver">
              <CFImage w="40px" pr="10px" src={yelp} alt="Sun Sushi Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/sun-sushi-point-grey/menu">
              <CFImage w="40px" pr="10px" src={zomato} alt="Sun Sushi Zomato" />
            </CFLink>
            {/* <CFLink href="https://www.instagram.com/yohachi_sushi/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sun Sushi Instagram"
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C3915F" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/Yohachi-Sushi-169349519752621">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sun Sushi Facebook"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/sun-sushi-vancouver">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Sun Sushi Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/sun-sushi-point-grey/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Sun Sushi Zomato"
                hover
              />
            </CFLink>
            {/* <CFLink href="https://www.instagram.com/yohachi_sushi/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sun Sushi Instagram"
                hover
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
