export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1601693840/sushisakura/homebg.jpg'
export const contactbg =
  'https://res.cloudinary.com/bonggie/image/upload/f_auto,q_auto:best/v1602734839/Yohachi%20Sushi/contactbg_hvvy94.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/bonggie/image/upload/f_auto,q_auto:best/v1602733779/Yohachi%20Sushi/mobileContactbg_se4xuu.jpg'
export const about =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735778/sunsushi/about.jpg'
export const foodGallery =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735780/sunsushi/foodGallery.png'
export const hero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735779/sunsushi/hero.jpg'
export const locationMap =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735779/sunsushi/locationMap.jpg'
export const logo =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735779/sunsushi/logo.png'
export const mobileHero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735780/sunsushi/mobileHero.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735780/sunsushi/mobileGallery.png'
export const mobileMap =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735779/sunsushi/mobileMap.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735781/sunsushi/mobilePromotion.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1684775834/sunsushi/orderPickupButton.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1684775558/sunsushi/deliveryButton.png'
export const promotions =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610735780/sunsushi/promotions.png'
