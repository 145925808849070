import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { foodGallery, mobileGallery } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery} w="90%" alt="Food Gallery" mb="20px" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="50px auto">
          <CFImage
            src={foodGallery}
            w="100%"
            maxWidth="1260px"
            alt="Food Gallery"
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
